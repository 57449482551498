import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { MdDownloadForOffline } from "react-icons/md"
import { FaRegEdit } from "react-icons/fa"
import { CommonFormTextInput, CommonFormToggle, CommonLoader, Copy } from '..'
import { IoCheckmarkCircle } from "react-icons/io5";
import { MdCancel } from "react-icons/md";
import { BiSolidError } from "react-icons/bi";
import API from '../../apis'

const Table = (props) => {
    const [showEdit, setShowEdit] = useState(false)
    const [value, setValue] = useState(null)
    const [callbackFn, setCallbackFn] = useState(null)
    const [isUpdating, setIsUpdating] = useState(false)
    const [error, setError] = useState(false)
    const [options, setOptions] = useState([])

    const showPopup = async function (key, cb, currentVal, type, listAPI) {
        if (type === "list") {
            setOptions(await listAPI())
        }
        setShowEdit(key)
        setCallbackFn(() => v => cb(v))
        setValue(currentVal)
    }

    const hidePopup = async function () {
        setShowEdit(false)
        setCallbackFn(null)
        setValue(null)
    }

    const updateValue = async function () {
        if (typeof callbackFn === "function" && value !== null) {
            setIsUpdating(true)
            const response = await callbackFn(value)
            if (response === true) hidePopup()
            else {
                setError(true)
                setTimeout(() => setError(false), 4000)
            }
            setIsUpdating(false)
        }
    }

    const uploadFile = async function () {
        if (typeof callbackFn === "function" && value !== null && value?.length === 1) {
            setIsUpdating(true)
            const fileUpload = await API.util.uploadPartnerDocuments({
                file: value[0],
                ext: value[0].name.split(".").pop(),
                size: value[0].size,
                type: value[0].type
            })
            console.log(fileUpload)
            const response = await callbackFn(fileUpload.data?.data)
            if (response === true) hidePopup()
            else {
                setError(true)
                setTimeout(() => setError(false), 4000)
            }
            setIsUpdating(false)
        }
    }

    const optionElements = typeof options[0] === "object"
        ? options.map(x => { if (!x.name) x.name = x.id; return x; }).sort((a, b) => a.name.localeCompare(b.name)).map((option, index) => (
            <option key={option.id} value={option.id}>{option.name} ({option.id})</option>
        ))
        : options.map(option => <option key={option} value={option}>{option}</option>)

    return (
        <div className={props.className || ""}>
            <div className="flex">
                <p className="w-36 text-center uppercase inline-block border-b border-sk-blue text-xs px-3 py-1 bg-sk-blue text-white rounded-t-xl font-semibold">{props.heading}</p>
                <p className="flex-1 border-b border-sk-blue"></p>
            </div>
            <table className="table-auto w-full border-collapse text-sm">
                <tbody className="*:border-b *:border-gray-300">
                    {React.Children.map(props.children, (e, i) => e && e.key ?
                        <tr key={i} className={"*:py-1 " + (e.props.rowclassname || "")}>
                            <td className="w-1/2 text-sm px-1">{e.key}</td>
                            <td className="w-1/2 font-semibold px-1">
                                {showEdit === e.key ? <div className="flex space-x-1 items-center">
                                    {e.props.switch
                                        ? <CommonFormToggle checked={e.props.value === "Yes"} onChange={setValue} />
                                        : e.props.list
                                            ? <select defaultValue={value} onChange={(e) => setValue(e.target.value)}>
                                                {optionElements}</select>
                                            : e.props.file ?
                                                <input type="file" id="sk-table-key-value-file" className="w-full" onChange={e => setValue(e.target.files)} />
                                                : <CommonFormTextInput id="sk-table-key-value-edit" className="flex-1" short={true} value={e.props.value} maxLength="100" onChange={setValue} />}
                                    {isUpdating ? <CommonLoader className="h-6 w-6 text-sk-blue"></CommonLoader> :
                                        error ? <BiSolidError className="w-6 h-6 text-red" /> : <>
                                            <IoCheckmarkCircle className="w-6 h-6 text-sk-blue" onClick={e.props.file ? uploadFile : updateValue} />
                                            <MdCancel className="w-6 h-6 text-gray-700" onClick={hidePopup} />
                                        </>
                                    }
                                </div> : <div>
                                    {e.props.download ? <Link target='_blank' to={process.env.REACT_APP_BACKEND_URL + "/util/download?name=" + e.props.download}>{e} <MdDownloadForOffline className="inline-block w-4 h-4 text-sk-blue" /></Link> : e}
                                    {e.props.update && typeof e.props.update === "function" && <FaRegEdit className="inline-block ml-2 -mt-1 text-sk-blue" onClick={() => showPopup(e.key, e.props.update, e.props.value)} />}
                                    {e.props.file && typeof e.props.file === "function" && <FaRegEdit className="inline-block ml-2 -mt-1 text-sk-blue" onClick={() => showPopup(e.key, e.props.file, e.props.value)} />}
                                    {e.props.listAPI && typeof e.props.listAPI === "function" && <FaRegEdit className="inline-block ml-2 -mt-1 text-sk-blue" onClick={() => showPopup(e.key, e.props.list, e.props.value, "list", e.props.listAPI)} />}
                                    {e.props.copy && <Copy text={e.props.copy} />}
                                    {e.props.subtext && <span className="text-xs text-gray-700"><br />{e.props.subtext}</span>}
                                </div>}
                            </td>
                        </tr>
                        : ""
                    )}
                </tbody>
            </table>
        </div>
    )
}

Table.propTypes = {
    className: PropTypes.string,
    heading: PropTypes.string,
    children: PropTypes.any,
}

export default Table